import useCurrentUser from "@/graphql/hooks/user/useUser";
import { useContext, useEffect } from "react";
import useRenewalOfferBatches from "@/graphql/hooks/renewalOfferBatches/useRenewalOfferBatches";
import { RenewalOfferContext } from "@/context/renewal-offer/context";
import useQueryManager from "./hooks/useQueryManager";
import useBatchProcessor from "./hooks/useBatchProcessor";
import useFilterState from "./hooks/useFilterState";

const useConnect = () => {
  const { setRenewalOfferState } = useContext(RenewalOfferContext);
  const { data: user } = useCurrentUser();
  const { data: batches } = useRenewalOfferBatches();

  const { batchesByCommunity } = useBatchProcessor(batches || undefined);
  const { filters, setFilters, handleChange } = useFilterState(
    user,
    batchesByCommunity,
    setRenewalOfferState
  );

  useQueryManager(filters);

  useEffect(() => {
    setRenewalOfferState({
      batchId: filters.batchId,
      communityId: filters.communityId,
      month: filters.month,
      year: filters.year,
    });
  }, [filters, setRenewalOfferState]);

  return {
    setFilters,
    filters,
    batchesByCommunity,
    handleChange,
  };
};

export default useConnect;
