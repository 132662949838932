import { useState, useEffect } from "react";
import { SelectChangeEvent } from "@mui/material";
import { User } from "@/model/User";
import { RenewalOffer } from "@/context/renewal-offer/context";
import { Filter, IndividualBatch, Value } from "../types";
import {
  getDefaultCommunity,
  getDefaultLeaseEndDate,
  getBatchIdForCommunity,
} from "../utils/defaultHelpers";
import useFilterAnalytics from "./useFilterAnalytics";

const useFilterState = (
  user: User | undefined,
  batchesByCommunity:
    | { [key: string]: { id: string; batches: IndividualBatch[] } }
    | undefined,
  setRenewalOfferState: RenewalOffer["setRenewalOfferState"]
) => {
  const [filters, setFilters] = useState<Filter>({
    community: undefined,
    communityId: undefined,
    leaseEndDate: undefined,
    batchId: undefined,
    month: undefined,
    year: undefined,
  });

  const { filterChangeTrackCall } = useFilterAnalytics(batchesByCommunity);

  useEffect(() => {
    setFilters((prevFilters) => {
      const community =
        prevFilters.community || getDefaultCommunity(batchesByCommunity)?.name;
      const communityId =
        prevFilters.communityId || getDefaultCommunity(batchesByCommunity)?.id;
      const leaseEndDate =
        prevFilters.leaseEndDate ||
        getDefaultLeaseEndDate(batchesByCommunity, community)?.leaseEndDate;
      const month =
        prevFilters.month ||
        getDefaultLeaseEndDate(batchesByCommunity, community)?.month;
      const year =
        prevFilters.year ||
        getDefaultLeaseEndDate(batchesByCommunity, community)?.year;
      const batchId = getBatchIdForCommunity(
        batchesByCommunity,
        leaseEndDate || "",
        community
      );

      return {
        community,
        communityId,
        leaseEndDate,
        batchId,
        month,
        year,
      };
    });
  }, [user, batchesByCommunity]);

  const handleChange = (e: SelectChangeEvent<unknown>) => {
    const value: Value = JSON.parse(e.target.value as string) as {
      id: string;
      community?: string;
      communityId?: string;
      leaseEndDate?: string;
      month?: string;
      year?: string;
    };
    setRenewalOfferState({
      batchId: value.id,
      communityId: value.communityId,
      month: value.month,
      year: value.year,
    });

    const community = value.community || filters.community;
    const filterChangeUpdate = {
      ...filters,
      community,
      communityId: value.communityId,
      leaseEndDate: value.leaseEndDate,
      month: value.month,
      year: value.year,
      batchId:
        value.id ||
        (community && batchesByCommunity?.[community]?.batches?.[0].id),
    };

    setFilters(filterChangeUpdate);
    filterChangeTrackCall(value, filterChangeUpdate);
  };

  return { filters, setFilters, handleChange };
};

export default useFilterState;
